import { createGlobalStyle } from 'styled-components';

export const InjectFont = createGlobalStyle`
  @font-face {
    font-family: 'Inter UI';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url('/assets-react/fonts/inter-regular.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Inter UI';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url('/assets-react/fonts/inter-bold.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Bitter';
    font-weight: 700;
    font-style: normal;
    font-display: block;
    src: url('/assets-react/fonts/bitter.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Bitter';
    font-weight: 900;
    font-style: normal;
    font-display: block;
    src: url('/assets-react/fonts/bitter-bolder.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url('/assets-react/fonts/material-symbols-outlined.woff2') format('woff2');
  }
`;
