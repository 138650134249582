import React, { useContext, createContext, PropsWithChildren } from 'react';
import { useRouter } from 'next/router';

export type ImageContextType = { firstImages: [string?]; maxFirstImages: number; curPath: string };
export const defaultImageContext: ImageContextType = {
  firstImages: [],
  maxFirstImages: 4,
  curPath: '',
};
const ImageContext = createContext<ImageContextType>(defaultImageContext);

export const useImage = () => {
  const context = useContext(ImageContext);

  return context;
};

export const useSetMaxFirstImages = (max: number) => {
  const context = useContext(ImageContext);
  context.maxFirstImages = max;
};

export const useImageIsEager = ({
  imageId,
  mobileWidth,
  mobileHeight,
  desktopWidth,
  desktopHeight,
}: {
  imageId: string;
  mobileWidth: number;
  mobileHeight: number;
  desktopWidth: number;
  desktopHeight: number;
}): boolean => {
  const router = useRouter();
  const curPath = router?.asPath;
  const context = useContext(ImageContext);
  if (context.curPath !== curPath) {
    context.curPath = curPath;
    context.firstImages = [];
  }

  const key = `${imageId}-${mobileWidth}-${desktopWidth}-${mobileHeight}-${desktopHeight}`;
  if (context.firstImages.includes(key)) {
    return true;
  }
  if (context.firstImages.length < context.maxFirstImages) {
    context.firstImages.push(key);

    return true;
  }

  return false;
};

export function ImageProvider({ children, value }: PropsWithChildren<{ value: any }>) {
  return <ImageContext.Provider value={value}>{children}</ImageContext.Provider>;
}
