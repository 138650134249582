import type { Config } from '../types';

export const config: Config = {
  baseUrl: 'http://localhost:3000',
  sitename: 'site-clubic',
  apiURL: 'http://localhost:4000/graphql',
  ssoUrl: 'https://connect.pp.clubic.com/',
  matomoId: 1,
  displayAd: false,
  imageApiHostname: '//pic.clubic.com',
  addEnv: 'dev',
  feedback: { imageId: '1872455', url: 'https://forum.clubic.com/t/449360' },
  sentry: {
    enabled: true,
  },
};
