import { useContext } from 'react';
import { apiLink, getApiClient } from '@marty-js/api-sdk';
import { checkIsConnected } from '@marty-js/api-sdk/services/sso';
import { AuthContext } from './AuthContext';

export type UserContextType = { isValid: boolean; avatar: string; username: string; ssoUrl: string; email: string };
export const defaultUserContext: UserContextType = {
  isValid: false,
  avatar: undefined,
  username: undefined,
  email: undefined,
  ssoUrl: undefined,
};

export function useSetLocalStorage() {
  const context = useContext(AuthContext);

  return (userToken: string) => {
    const apiClient = getApiClient();

    const checkConnection = async () => {
      apiClient.setLink(apiLink());

      const userInfo = await checkIsConnected(apiClient);

      if (userInfo && userInfo?.username) {
        context.username = userInfo?.username;
        context.isValid = true;
        context.avatar = userInfo?.avatar;
        context.email = userInfo?.email;
      }
      if (userInfo && !userInfo?.isValid) {
        localStorage.removeItem('userToken');
      }
    };

    localStorage.setItem('userToken', userToken);

    checkConnection();
  };
}
