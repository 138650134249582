const consoleDebug = (msg: any) => {
  if (typeof window !== 'undefined') {
    if (window.localStorage.getItem('consoleDebug')) {
      // eslint-disable-next-line no-console
      console.log(msg);
    }
  }
};

export default consoleDebug;
