import parseJSON from 'date-fns/parseJSON';
import formatDistance from 'date-fns/formatDistanceToNow';

import format from 'date-fns/format';
import fr from 'date-fns/locale/fr';
import isSameDay from 'date-fns/isSameDay';
import differenceInDays from 'date-fns/differenceInDays';
import startOfDay from 'date-fns/startOfDay';

// import en from 'date-fns/locale/en-GB';

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const selectLocale = (locale: string): any => {
  if (locale === 'en-GB') {
    return fr;
  }

  return fr;
};

export const formatDateWithTime = (dateTime: string, pattern = "dd MMMM yyyy 'à' HH'h'mm", locale = 'fr'): string => {
  const date = parseJSON(dateTime);

  const localeLanguage = selectLocale(locale);

  return format(date, pattern, { locale: localeLanguage });
};

export const formatDate = (dateTime: string | Date, pattern = 'dd MMMM yyyy', locale = 'fr'): string => {
  const date = parseJSON(dateTime);

  const localeLanguage = selectLocale(locale);
  try {
    return format(date, pattern, { locale: localeLanguage });
  } catch {
    return dateTime?.toString();
  }
};

export const displayDateDiff = (dateTime: string, locale = 'fr') => {
  const date = parseJSON(dateTime);

  const localeLanguage = selectLocale(locale);

  return capitalizeFirstLetter(
    formatDistance(date, {
      addSuffix: true,
      locale: localeLanguage,
      includeSeconds: false,
    }),
  );
};

export const getHistoryDate = (currentDate: Date, previousDate: Date | null, locale: string): string => {
  const today = startOfDay(new Date());
  const currentDay = startOfDay(currentDate);
  const previousDay = previousDate ? startOfDay(previousDate) : null;

  if (!previousDay || !isSameDay(previousDay, currentDay)) {
    const diffInDays = differenceInDays(today, currentDay);

    if (diffInDays === 0) {
      return '';
    }

    return `${formatDate(currentDay, 'EEEE dd MMMM', locale)} `;
  }

  return '';
};
