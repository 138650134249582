import React, { PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { SkinContextProps, SkinProps } from '../atoms/types';

const SkinContext = createContext<SkinContextProps>({});

export const useSkinContext = () => {
  return useContext(SkinContext);
};

export const useSetColor = (color: string) => {
  const context = useContext(SkinContext);

  if (context.skin.color !== color) {
    context.skin.color = color;

    context.setSkin({
      ...context.skin,
    });
  }
};

export const useSetLink = (link: string) => {
  const context = useContext(SkinContext);

  if (context.skin.link !== link) {
    context.skin.link = link;

    context.setSkin({
      ...context.skin,
    });
  }
};

export const useSetSticky = (sticky: boolean) => {
  const context = useContext(SkinContext);

  if (context.skin.sticky !== sticky) {
    context.skin.sticky = sticky;

    context.setSkin({
      ...context.skin,
    });
  }
};

export const useSetLeft = (left: string) => {
  const context = useContext(SkinContext);

  if (context.skin.left !== left) {
    context.skin.left = left;

    context.setSkin({
      ...context.skin,
    });
  }
};

export const useSetRight = (right: string) => {
  const context = useContext(SkinContext);

  if (context.skin.right !== right) {
    context.skin.right = right;

    context.setSkin({
      ...context.skin,
    });
  }
};

export const useSetTop = (top: string) => {
  const context = useContext(SkinContext);

  if (context.skin.top !== top) {
    context.skin.top = top;

    context.setSkin({
      ...context.skin,
    });
  }
};

export function SkinProvider({ children, value }: PropsWithChildren<{ value: SkinProps }>) {
  const [skin, setSkin] = React.useState<SkinProps>(value);

  const newValue = useMemo(
    () => ({
      skin,
      setSkin,
    }),
    [skin, setSkin],
  );

  return <SkinContext.Provider value={newValue}>{children}</SkinContext.Provider>;
}
