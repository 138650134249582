import React, { useContext, createContext, useEffect, useMemo, useState, ReactElement } from 'react';
import { useProgrammableComponent } from '@marty-js/api-sdk/services/programmableComponent';
import type { GetProgrammableComponentVariables } from '@marty-js/api-sdk/__generated__/GetProgrammableComponent';
import type { ZoneAdServer } from '@marty-js/api-sdk/__generated__/globalTypes';
import type { AdContextType, DataZone, ServerSideZones } from './types';
import type { ComponentPropsQuery } from '../types';

declare global {
  interface Window {
    __updateModalDisplayConditions: (val: boolean) => void;
  }
}

export const defaultAdContext: AdContextType = {
  adUnit: '',
  site: null,
  tags: [],
  isSafe: true,
  env: 'prod',
  isModal: true,
};

const AdContext = createContext<AdContextType>(defaultAdContext);

export const useAdContext = () => {
  const context = useContext(AdContext);

  return context;
};

export const useAddServerSideZones = (zone: ServerSideZones) => {
  const context = useAdContext();
  const [data, setData] = useState<DataZone>(null);
  useEffect(() => {
    const curZone = context.serverSideZones?.find((val) => val.zoneSlug === zone.zoneSlug);
    if (curZone) {
      curZone.setData = (val) => {
        setData(val);
      };
    } else if (context.serverSideZones) {
      context.serverSideZones.push(zone);
      zone.setData = (val) => {
        setData(val);
      };
      context.setServerSideZones([...context.serverSideZones]);
    }
  }, [context, setData, zone]);

  return data;
};

const ProgrammableComponentWrapper: React.FC<{ children?: ReactElement }> = ({ children }) => {
  const { serverSideZones, tags } = useAdContext();
  const { fetch, countImpression, countClickRef } = useProgrammableComponent();

  useEffect(() => {
    const zoneLoading: { [key: string]: boolean } = {};
    if (serverSideZones.length && tags.length) {
      const zones = serverSideZones
        .map(({ zoneSlug, keywords, dataOk }): ZoneAdServer => {
          zoneLoading[zoneSlug] = true;

          return dataOk
            ? null
            : {
                zoneSlug,
                keywords: tags.join(',') + (keywords ? `,${keywords}` : ''),
              };
        })
        .filter(Boolean);

      if (zones.length) {
        const gdpr =
          document.cookie
            .split('; ')
            ?.find((row) => row.startsWith('euconsent-v2'))
            ?.split('=')[1] ?? '0';

        const variables: GetProgrammableComponentVariables = {
          userAgent: window.navigator.userAgent,
          urlReferer: window.location.href,
          gdpr,
          userToken: window.localStorage.getItem('actionToken'),
          zones,
        };

        const fetchData = fetch(variables);
        fetchData.then((getProgrammableComponent) => {
          getProgrammableComponent?.programmableComponent.zones.forEach((zone): void => {
            if (zone) {
              const curZone = serverSideZones?.filter((serverSideZone) => serverSideZone.zoneSlug === zone.zone)[0];
              if (curZone) {
                zoneLoading[curZone.zoneSlug] = false;
                let component: ComponentPropsQuery<any>;
                if (zone.component) {
                  component = { ...zone.component, data: JSON.parse(zone.component.data) } as ComponentPropsQuery<any>;
                }
                const data: DataZone = {
                  component,
                  isEmpty: false,
                  impressionCount: () => countImpression(zone.impressionCount),
                  clickRef: () => countClickRef(zone.clickRef),
                };

                curZone.setData(data);
              }
            }
          });

          serverSideZones?.forEach((serverSideZone) => {
            if (serverSideZone.zoneSlug in zoneLoading) {
              if (zoneLoading[serverSideZone.zoneSlug]) {
                serverSideZone.setData({ isEmpty: true });
              }
            }
          });
        });
      }
    }
  }, [serverSideZones, tags, fetch, countImpression, countClickRef]);

  return children;
};

const CallbackWrapper: React.FC<{ children?: ReactElement }> = ({ children }) => {
  const context = useAdContext();

  useEffect(() => {
    if (!window.__updateModalDisplayConditions) {
      window.__updateModalDisplayConditions = (val) => {
        context.isModal = val;
      };
    }
  }, [context]);

  return <ProgrammableComponentWrapper>{children}</ProgrammableComponentWrapper>;
};

export function AdProvider({ children }: { children?: ReactElement }) {
  const [serverSideZones, setServerSideZones] = useState<ServerSideZones[]>([]);

  const value: AdContextType = useMemo(
    () => ({
      adUnit: '',
      site: null,
      darkMode: false,
      tags: [],
      isSafe: true,
      env: 'prod',
      isModal: true,
    }),
    [],
  );

  value.serverSideZones = serverSideZones;
  value.setServerSideZones = setServerSideZones;

  return (
    <AdContext.Provider value={value}>
      <CallbackWrapper>{children}</CallbackWrapper>
    </AdContext.Provider>
  );
}
