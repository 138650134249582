import getConfig from 'next/config';
import { Config } from './types';

import { config as defaultConfig } from './env/default.config';

const nextConfig = getConfig();
const env = nextConfig?.publicRuntimeConfig?.appEnv || 'default';
const version = nextConfig?.publicRuntimeConfig?.version;
// eslint-disable-next-line import/no-dynamic-require
const { config: envConfig } = require(`./env/${env}.config`);

export const config: Config = { version, ...defaultConfig, ...envConfig };
