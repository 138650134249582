import type { Config } from '../types';

export const config: Config = {
  env: 'prod',
  baseUrl: 'https://www.clubic.com',
  apiURL: 'https://api.clubic.com/graphql',
  ssoUrl: 'https://connect.clubic.com/',
  imageApiHostname: '//pic.clubic.com',
  feedback: { imageId: '1897011', url: 'https://forum.clubic.com/t/449360' },
  addEnv: 'prod',
};
