import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import type { Theme } from './types';

export interface ThemeSwitcherContextType {
  currentTheme: Theme;
  switchTheme: (theme?: string) => void;
}

export interface ThemeSwitcherHook extends ThemeSwitcherContextType {
  isDarkTheme: boolean;
}

export const ThemeSwitcherContext = React.createContext<ThemeSwitcherContextType>({
  currentTheme: null,
  switchTheme() {},
});

export const useThemeSwitcher: () => ThemeSwitcherHook = () => {
  const themeSwitcherContext = useContext<ThemeSwitcherContextType>(ThemeSwitcherContext);

  return {
    ...themeSwitcherContext,
    isDarkTheme: themeSwitcherContext.currentTheme.isDark,
  };
};

export const MODE_PREFERENCE_KEY = 'preferMode';

const getPrefersTheme = (): string => {
  if (typeof window === 'undefined') {
    return null;
  }

  const localStoragePreference = window?.localStorage.getItem(MODE_PREFERENCE_KEY);
  if (localStoragePreference) {
    return localStoragePreference;
  }

  // Hack to get theme from legacy
  if (document.cookie.match(/theme=dark/i) != null) {
    return 'dark';
  }
  if (document.cookie.match(/theme=light/i) != null) {
    return 'light';
  }

  return window?.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
};

export const DarkModeProvider = ({
  lightTheme,
  darkTheme,
  otherTheme = {},
  children,
}: React.PropsWithChildren<{ lightTheme: Theme; darkTheme: Theme; otherTheme?: { [key: string]: Theme } }>) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(lightTheme);

  const setTheme = useCallback(
    (theme?: string) => {
      if (theme in otherTheme) {
        setCurrentTheme(otherTheme[theme]);
      } else if (theme === 'dark') {
        setCurrentTheme(darkTheme);
      } else {
        setCurrentTheme(lightTheme);
      }
    },
    [lightTheme, darkTheme, otherTheme, setCurrentTheme],
  );

  const switchTheme = useCallback<(theme?: string) => void>(
    (theme = null) => {
      if (theme) {
        localStorage.setItem(MODE_PREFERENCE_KEY, theme);
        setTheme(theme);
      } else if (currentTheme === lightTheme) {
        localStorage.setItem(MODE_PREFERENCE_KEY, 'dark');
        setTheme('dark');
      } else {
        localStorage.setItem(MODE_PREFERENCE_KEY, 'light');
        setTheme('light');
      }
    },
    [lightTheme, currentTheme, setTheme],
  );

  useEffect(() => {
    const prefersTheme = getPrefersTheme();
    document.documentElement.setAttribute('data-theme', prefersTheme);
    setTheme(prefersTheme);
  }, [setTheme]);

  const themeSwitcher = useMemo<ThemeSwitcherContextType>(
    () => ({ currentTheme, switchTheme }),
    [currentTheme, switchTheme],
  );

  return (
    <ThemeSwitcherContext.Provider value={themeSwitcher}>
      <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
    </ThemeSwitcherContext.Provider>
  );
};
