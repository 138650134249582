import type { Config } from '../types';

export const config: Config = {
  env: 'preview',
  baseUrl: 'https://preview.clubic.com',
  ssoUrl: 'https://connect.clubic.com/',
  apiURL: 'https://preview-api.clubic.com/graphql',
  imageApiHostname: '//pic.clubic.com',
  feedback: { imageId: '1897011', url: 'https://forum.clubic.com/t/449360' },
  addEnv: 'preview',
};
