import { useEffect, useRef } from 'react';
import { useSdkConfig } from './config';

export const AffilizzScriptComponent = (): null => {
  const sdkConfig = useSdkConfig();
  const affilizzScriptLoaded = useRef<boolean>(false);

  useEffect(() => {
    if (!affilizzScriptLoaded.current && sdkConfig.hasAffilizzLink) {
      affilizzScriptLoaded.current = true;
      const affilizzLocScript = document.createElement('script');
      affilizzLocScript.id = 'affilizz-loc-script';
      affilizzLocScript.type = 'text/javascript';
      affilizzLocScript.async = true;
      affilizzLocScript.src = 'https://sc.affilizz.com/affilizz.js';
      document.body.appendChild(affilizzLocScript);
    }
  }, [sdkConfig.hasAffilizzLink]);

  return null;
};
