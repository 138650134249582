const slugify = (str: string) => {
  return str
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, ' ')
    .replace(/[^\w-]+/g, ' ')
    .trim()
    .replace(/[\s-]+/g, '-');
};

export default slugify;
