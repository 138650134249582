import { css } from 'styled-components';

export enum DEVICE_SIZE {
  SMALL = 320,
  MEDIUM = 768,
  LARGE = 1024,
  X_LARGE = 1440,
}

export const MOBILE_SIZES = [DEVICE_SIZE.SMALL, DEVICE_SIZE.MEDIUM];
export const DESKTOP_SIZES = [DEVICE_SIZE.LARGE, DEVICE_SIZE.X_LARGE];

const DEVICE_SIZE_KEYS: string[] = Object.keys(DEVICE_SIZE).filter(
  (size) => typeof DEVICE_SIZE[size as any] === 'number',
);
export const DEVICE_SIZE_VALUES: number[] = DEVICE_SIZE_KEYS.map((size) => parseInt(DEVICE_SIZE[size as any], 10));

export const mq = {
  gte: (size: DEVICE_SIZE, styles: any) => css`
    @media (min-width: ${size}px) {
      ${styles};
    }
  `,
  lte: (size: DEVICE_SIZE, styles: any) => css`
    @media (max-width: ${size}px) {
      ${styles};
    }
  `,
};

export const imgSize = (size: DEVICE_SIZE, width: string) => {
  return `(min-width: ${size}px) ${width}`;
};
