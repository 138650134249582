import React, { useContext, createContext, PropsWithChildren } from 'react';

export type MessageType = { [key: string]: string | MessageType };
const TranslationContext = createContext<MessageType>({});
type ParamType = { [key: string]: string | number };

export type TranslationType = (key: string, param?: ParamType) => string;

export const translation = (message: MessageType = null): TranslationType => {
  return (key: string, param: ParamType = {}): string => {
    if (key) {
      let t: MessageType | string = message;
      // @ts-ignore
      key.split('.').forEach((val: MessageType) => {
        // @ts-ignore
        t = t && val in t ? t[val] : null;
      });

      if (typeof t === 'string') {
        // eslint-disable-next-line no-restricted-syntax
        for (const [k, value] of Object.entries(param)) {
          // @ts-ignore
          t = t.replace(`{{${k}}}`, value || '');
        }

        return t;
      }

      return key;
    }

    return null;
  };
};

export const useTranslation = (): TranslationType => {
  const context = useContext(TranslationContext);

  return translation(context);
};

export type TransProps = {
  keyTrans: string;
  // components?: { [key: string]: ReactComponentElement<any> };
  param?: ParamType;
};

export const Trans = ({ keyTrans, param }: TransProps) => {
  const t = useTranslation();

  const val = t(keyTrans, param);
  /*
  const regexp = /(.+?)<([A-Z].+?)>(.+?)<\/\2>/g;
  //console.log(component);
  // todo use component
  */

  return val;
};

export function TranslationProvider({ children, value }: PropsWithChildren<{ value: any }>) {
  return <TranslationContext.Provider value={value}>{children}</TranslationContext.Provider>;
}
