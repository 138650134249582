import styled, { css } from 'styled-components';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';

export type LinkedSection = {
  title: string;
  url: string;
  id: string;
  children: {
    [key: string]: LinkedSection;
  };
};
export const MobileOnly = styled.div`
  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

export const isDesktop = (): boolean => {
  if (window?.matchMedia) {
    return window.matchMedia(`(min-width: ${DEVICE_SIZE.LARGE}px)`).matches;
  }

  return false;
};

export const slugify = (text: string): string => {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
};

export const isMobile = () => !isDesktop();

export interface LinkedTagsProps {
  url: string;
  title: string;
}

const linkedSectionsDeep = (
  linkedSections: { [key: string]: LinkedSection },
  parent: LinkedSection | null,
  linked: LinkedTagsProps[],
) => {
  if (typeof linkedSections !== 'object') {
    return;
  }
  Object.keys(linkedSections).forEach((key) => {
    const linkedSection = linkedSections[key];
    if (linkedSection?.children && Object.keys(linkedSection.children).length) {
      linkedSectionsDeep(linkedSection.children, linkedSection, linked);
    } else if (parent) {
      linked.push({
        url: linkedSection.url,
        title: linkedSection.title,
      });
    }
  });
};

export const linkedSectionsTransform = (linkedSections: { [key: string]: LinkedSection }): LinkedTagsProps[] => {
  const linked: LinkedTagsProps[] = [];

  linkedSectionsDeep(linkedSections, null, linked);

  return linked;
};
