import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { apiLink, getApiClient } from '@marty-js/api-sdk';
import { checkIsConnected } from '@marty-js/api-sdk/services/sso';
import { useSdkConfig } from './config';

type UserContextType = { isValid: boolean; avatar: string; username: string; email: string; ssoUrl: string };
export const defaultUserContext: UserContextType = {
  isValid: false,
  avatar: undefined,
  username: undefined,
  email: undefined,
  ssoUrl: undefined,
};
export const AuthContext = createContext<UserContextType>(defaultUserContext);

export const AuthProvider = ({ children, value }: PropsWithChildren<{ value: UserContextType }>) => {
  const [userContext, setUserContext] = useState<UserContextType>(value);

  const apiClient = getApiClient();
  const sdkConfig = useSdkConfig();

  const context = useContext(AuthContext);

  useEffect(() => {
    const userToken = localStorage.getItem('userToken') ?? null;

    if (userToken) {
      apiClient.setLink(apiLink());

      const checkConnection = async () => {
        const userInfo = await checkIsConnected(apiClient);

        if (userInfo && userInfo.isValid) {
          context.isValid = true;
          context.avatar = userInfo?.avatar;
          context.email = userInfo?.email;
          context.username = userInfo?.username;
          context.ssoUrl = undefined;
          setUserContext(context);
        }

        if (userInfo && !userInfo.isValid) {
          localStorage.removeItem('userToken');
        }
      };

      checkConnection();
    } else {
      context.isValid = false;
      context.avatar = undefined;
      context.username = undefined;
      context.ssoUrl = localStorage.getItem('ssoUrl');
      setUserContext(context);
    }
  }, [apiClient, context, sdkConfig.baseUrl, sdkConfig.ssoUrl]);

  return <AuthContext.Provider value={userContext}>{children}</AuthContext.Provider>;
};
