import { useEffect } from 'react';
import { trackClick } from '@marty-js/api-sdk/services/matomo';
import { getApiClient } from '@marty-js/api-sdk';

const TrackLinks = (): null => {
  const apiClient = getApiClient();

  useEffect(() => {
    const handlerClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      const link = target.closest('a');

      if (link && link.href) {
        const userToken = localStorage.getItem('actionToken');
        const urlPage = window.location.href;
        const urlClick = link.href;

        trackClick(apiClient, userToken, urlPage, urlClick);
      }
    };

    document.addEventListener('click', handlerClick);

    return () => {
      document.removeEventListener('click', handlerClick);
    };
  }, [apiClient]);

  return null;
};

export default TrackLinks;
