import React from 'react';
import Head from 'next/head';
import { useMetaSeoContext } from '@marty-js/sdk/src/utils/metaSeoContext';
import { useTranslation } from '@marty-js/design/src/utils/translation';

const MetaSEO = () => {
  const t = useTranslation();
  const metaSeoContext = useMetaSeoContext();

  const title =
    (metaSeoContext.title || metaSeoContext.pageTitle) +
    (metaSeoContext.pageNumber > 1 ? t('metaSeo.page', { page: metaSeoContext.pageNumber }) : '');
  const description = metaSeoContext.description || metaSeoContext.pageTitle;

  const ogTitle: string = metaSeoContext.ogTitle || title;
  const twitterTitle: string = metaSeoContext.twitterTitle || title;

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta
        name="robots"
        content={`${
          metaSeoContext.robots ? `${metaSeoContext.robots}, ` : ''
        }max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
      />

      <meta property="og:locale" content="fr_FR" key="og:locale" />
      <meta property="og:site_name" content="clubic.com" key="og:site_name" />
      <meta property="og:title" content={ogTitle} key="og:title" />
      <meta property="og:description" content={description} key="og:description" />
      <meta property="og:url" content={metaSeoContext.url} key="og:url" />
      <meta property="og:image" content={metaSeoContext.imageUrl} key="og:image" />
      <meta property="og:type" content="article" key="og:type" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@clubic" />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:image" content={metaSeoContext.imageUrl} />
      <meta name="twitter:url" content={metaSeoContext.url} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:domain" content="clubic.com" />
      <meta property="article:publisher" content="https://www.facebook.com/Clubic" key="article:publisher" />
      {metaSeoContext.canonical && <link rel="canonical" href={metaSeoContext.canonical} />}
      {metaSeoContext.date && (
        <>
          <meta property="article:published_time" content={metaSeoContext.date.publishedAt} />
          <meta property="article:modified_time" content={metaSeoContext.date.updatedAt} />
        </>
      )}
    </Head>
  );
};

export default MetaSEO;
