import React, { createContext, useContext } from 'react';
import { ImageProps, LinkProps } from '../atoms/types';

const ImageContext = createContext(() => null);
const LinkContext = createContext(() => null);

export const useImageComponent = (): React.FC<ImageProps> => {
  return useContext(ImageContext);
};

export const ImageComponent = (props: ImageProps) => {
  const Image = useImageComponent();

  return <Image {...props} />;
};

export const useLinkComponent = (): React.FC<LinkProps> => {
  return useContext(LinkContext);
};

export const LinkComponent = (props: LinkProps) => {
  const Link = useLinkComponent();

  return <Link {...props} />;
};

export function ComponentProvider({
  imageComponent,
  linkComponent,
  children,
}: React.PropsWithChildren<{
  imageComponent?: any;
  linkComponent?: any;
}>) {
  return (
    <ImageContext.Provider value={imageComponent}>
      <LinkContext.Provider value={linkComponent}>{children}</LinkContext.Provider>
    </ImageContext.Provider>
  );
}
