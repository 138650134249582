import React from 'react';
import type { LinkProps } from './types';
import { LinkComponent } from '../utils/component';

export const Link = ({ ...props }: React.PropsWithChildren<LinkProps>) => {
  const { href, rel, openInNewWindow, children, stylized } = props;

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={href}
      className={(stylized ? 'un-styled-linked ' : '') + props.className}
      rel={rel?.length ? rel.join(' ') : null}
      target={openInNewWindow ? '_blank' : null}
    >
      {children}
    </a>
  );
};

export const UndecoratedNavLink = (props: React.PropsWithChildren<LinkProps>) => (
  <LinkComponent stylized={false} {...props} />
);

export const SimpleLink = (props: React.PropsWithChildren<LinkProps>) => {
  const { rel, className, children } = props;

  return (
    <a {...props} rel={rel?.length ? rel.join(' ') : null} className={`simple${className ? ` ${className}` : ''}`}>
      {children}
    </a>
  );
};

export const BaseLink = (props: React.PropsWithChildren<LinkProps>) => {
  const { rel, className, children } = props;

  return (
    <a
      {...props}
      rel={rel?.length ? rel.join(' ') : null}
      className={`un-styled-linked${className ? ` ${className}` : ''}`}
    >
      {children}
    </a>
  );
};

export const ExternalLink = (props: React.PropsWithChildren<LinkProps>) => (
  <SimpleLink {...props} target="_blank" rel={['nofollow', 'noreferrer']} />
);

export const AffiliateLink = (props: React.PropsWithChildren<LinkProps>) => (
  <SimpleLink {...props} target="_blank" rel={['nofollow', 'no-referrer-when-downgrade']} />
);

export const MinifiedExternalLink = (props: React.PropsWithChildren<LinkProps>) => (
  <SimpleLink {...props} target="_blank" rel={['nofollow', 'noreferrer']} referrerPolicy="no-referrer-when-downgrade" />
);
