import React, { useContext } from 'react';
import { TranslationType } from '@marty-js/design/src/utils/translation';

export type SdkConfig = {
  hostname: string;
  baseUrl: string;
  ssoUrl: string;
  sitename: string;
  imageApiHostname: string;
  displayAd: boolean;
  hasAffilizzLink?: boolean;
  feedback: { imageId: string; url: string };
  env: string;
  version: string;
  t: TranslationType;
};

export const SdkConfigContext = React.createContext<SdkConfig>({
  hostname: null,
  baseUrl: null,
  ssoUrl: null,
  sitename: null,
  imageApiHostname: null,
  displayAd: null,
  hasAffilizzLink: false,
  feedback: { imageId: null, url: null },
  env: null,
  version: 'v0.0.1',
  t: (key: string) => key,
});

export const useSdkConfig: () => SdkConfig = () => {
  return useContext<SdkConfig>(SdkConfigContext);
};

export function SdkConfigProvider({ config, children }: React.PropsWithChildren<{ config: SdkConfig }>) {
  return <SdkConfigContext.Provider value={config}>{children}</SdkConfigContext.Provider>;
}
