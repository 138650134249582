import type { BackgroundThemes, Theme, ThemeColor, ThemeColorsPalette, Typography } from '../utils/types';

export const palette: ThemeColorsPalette = {
  black: '#232728',
  blackAlt: '#212C42',
  darkBlack: '#1b1b1b',
  white: '#FFFFFF',
  darkWhite: '#f2f4f6',
  grey: '#777777',
  darkGrey: '#54617C',
  red: '#E4002A',
  redMedium: '#FF5978',
  darkRed: '#AF0524',
  blue: '#3F547E',
  blueMedium: '#99A4BA',
  darkBlue: '#37486D',
  green: '#009e76',
  darkGreen: '#018262',
  greyBlue: '#526C8A',
  darkGreyBlue: '#3F547E',
  lightGreyBlue: '#a4adbd',
  // todo: '#F2F4F6',
};

export const typography: Typography = {
  defaultFont: "'Inter UI', Arial, sans-serif",
  primaryFont: "'Bitter', serif",
};

export const lightThemeColor: ThemeColor = {
  background: palette.white,
  foreground: palette.blackAlt,
  medium: palette.blueMedium,
  primary: palette.red,
  primaryContrast: palette.white,
  title: palette.blackAlt,
};

export const lightBackgroundTheme: BackgroundThemes = {
  contrast: {
    backgroundColor: palette.darkWhite,
    linkColor: 'inherit',
    textColor: palette.blackAlt,
  },
  flashy: {
    backgroundColor: palette.red,
    linkColor: palette.white,
    textColor: palette.white,
  },
  navy: {
    backgroundColor: palette.blue,
    linkColor: palette.white,
    textColor: palette.white,
  },
  light: {
    backgroundColor: palette.grey,
    textColor: palette.blackAlt,
    linkColor: palette.red,
  },
  transparent: {
    backgroundColor: 'transparent',
    textColor: 'inherit',
    linkColor: 'inherit',
  },
};
export const lightTheme: Theme = {
  isDark: false,
  palette,
  typography,
  colors: lightThemeColor,
  backgroundTheme: lightBackgroundTheme,
};

export const darkThemeColor: ThemeColor = {
  background: palette.black,
  foreground: palette.white,
  medium: palette.white,
  primary: palette.redMedium,
  primaryContrast: palette.white,
  title: palette.white,
};

export const darkBackgroundTheme: BackgroundThemes = {
  contrast: {
    backgroundColor: palette.darkBlack,
    linkColor: 'inherit',
    textColor: palette.white,
  },
  flashy: {
    backgroundColor: palette.redMedium,
    linkColor: palette.white,
    textColor: palette.white,
  },
  navy: {
    backgroundColor: palette.blue,
    linkColor: palette.white,
    textColor: palette.white,
  },
  light: {
    backgroundColor: palette.grey,
    textColor: palette.blackAlt,
    linkColor: palette.redMedium,
  },
  transparent: {
    backgroundColor: 'transparent',
    textColor: 'inherit',
    linkColor: 'inherit',
  },
};

export const darkTheme: Theme = {
  isDark: true,
  palette,
  typography,
  colors: darkThemeColor,
  backgroundTheme: darkBackgroundTheme,
};
