import sha1 from 'crypto-js/sha1';
import slugify from './slugify';

export type ImageSrc = {
  url: string;
  pixelDensity: number;
};
type SrcSet = {
  mobile: ImageSrc[];
  tablet: ImageSrc[];
  desktop: ImageSrc[];
};

export type ImageQueryParams = Partial<{
  fit: 'crop' | 'smartCrop' | 'scale' | 'max' | 'min';
  height: number;
  width: number;
  crop: 'center' | string;
  format: 'jpeg' | 'png' | 'webp';
  quality: number;
  mask: string;
  maskposition:
    | 'top,left'
    | 'top'
    | 'top |right'
    | 'left'
    | 'center'
    | 'right'
    | 'bottom |left'
    | 'bottom'
    | 'bottom,right';
  masksize: string;
  blur: number;
}>;

export type ImageConfig = {
  hostname: string;
  imageId: string;
  webp?: boolean;
  mobileWidth: number;
  mobileHeight: number;
  tabletWidth: number;
  tabletHeight: number;
  desktopWidth: number;
  desktopHeight: number;
  alt?: string;
  title?: string;
  extension?: string;
  maxWidth?: number;
};

export type StdImageConfig = {
  hostname: string;
  imageId: string;
  webp?: boolean;
  width: number;
  height: number;
  extension?: string;
  title?: string;
};

const beautifyImageUrl = (config: StdImageConfig): string => {
  const { imageId = false, width, height, hostname, title } = config;
  let extension = config.webp ? '.webp' : `.${config.extension}`;
  if (extension === '.jpeg') {
    extension = '.jpg';
  }
  const slug = slugify(title);
  const param = {
    id: String(imageId),
    width: String(width),
    height: String(height),
    fit: 'smartCrop',
    slug,
    extension,
  };

  const hash = sha1(JSON.stringify(param)).toString().slice(-8);
  const url = `${hostname}/${hash}${imageId}/${width}x${height}/smart/${slug}${extension}`;

  return url;
};

export const getImageUrl = (config: StdImageConfig): string => {
  const { imageId, webp = false, width, height, hostname, extension, title } = config;

  if (title && (extension || webp)) {
    return beautifyImageUrl(config);
  }

  const resourcePath = `/v1/images/${imageId}/raw${webp ? '.webp' : ''}`;

  const queryParams: ImageQueryParams = {
    fit: 'smartCrop',
    width,
    height,
  };
  const queryString = Object.entries(queryParams)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const path = `${resourcePath}?${queryString}`;
  const hash = sha1(`${path}54b55408a530954b553ff79e98`);

  return `${hostname}${path}&hash=${hash}`;
};

export const getSrcSet = ({
  imageId,
  webp = false,
  mobileWidth,
  mobileHeight,
  tabletWidth,
  tabletHeight,
  desktopWidth,
  desktopHeight,
  hostname,
  title,
  extension,
  maxWidth,
}: ImageConfig): SrcSet => {
  const mobileRatio = mobileWidth / mobileHeight;
  const tabletRatio = tabletWidth / tabletHeight;
  const desktopRatio = desktopWidth / desktopHeight;

  const imageMobilePixelDensity = maxWidth && mobileWidth >= maxWidth ? [1] : [1, 2];
  const imageTabletPixelDensity = maxWidth && tabletWidth >= maxWidth ? [1] : [1, 2];
  const imageDesktopPixelDensity = maxWidth && desktopWidth >= maxWidth ? [1] : [1, 2];

  const mobileSrcSet = imageMobilePixelDensity.map((pixelDensity) => {
    let ratio = 1;
    let newPixelDensity = pixelDensity;
    if (mobileWidth > 450) {
      newPixelDensity = pixelDensity + 1;
      ratio = newPixelDensity / 2;
    } else {
      ratio = pixelDensity;
    }
    let width = mobileWidth * ratio;
    if (maxWidth && width > maxWidth) {
      width = maxWidth;
    }
    const url = getImageUrl({
      hostname,
      imageId,
      width: Math.ceil(width),
      height: Math.ceil(width / mobileRatio),
      webp,
      title,
      extension,
    });

    return { url, pixelDensity: newPixelDensity };
  });

  const tabletSrcSet = imageTabletPixelDensity.map((pixelDensity): ImageSrc => {
    const ratio = pixelDensity > 1 && tabletWidth > 400 ? 1.5 : pixelDensity;
    let width = tabletWidth * ratio;
    if (maxWidth && width > maxWidth) {
      width = maxWidth;
    }
    const url = getImageUrl({
      hostname,
      imageId,
      width: Math.ceil(width),
      height: Math.ceil(width / tabletRatio),
      webp,
      title,
      extension,
    });

    return { url, pixelDensity };
  });

  const desktopSrcSet = imageDesktopPixelDensity.map((pixelDensity) => {
    let width = desktopWidth * pixelDensity;
    if (maxWidth && width > maxWidth) {
      width = maxWidth;
    }
    if (width > 1600) {
      width = 1600;
    }
    const url = getImageUrl({
      hostname,
      imageId,
      width: Math.ceil(width),
      height: Math.ceil(width / desktopRatio),
      webp,
      title,
      extension,
    });

    return { url, pixelDensity };
  });

  return {
    mobile: mobileSrcSet,
    tablet: tabletSrcSet,
    desktop: desktopSrcSet,
  };
};
