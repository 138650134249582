import React, { useEffect } from 'react';
import { useAnalyticsContext } from '@marty-js/sdk/src/utils/AnalyticsContext';

declare global {
  interface Window {
    WonderPush: any;
  }
}

const WonderPush = (): React.ReactElement => {
  const analyticsContext = useAnalyticsContext();

  useEffect(() => {
    if (!window.WonderPush && analyticsContext?.type === 'item') {
      window.WonderPush = window.WonderPush || [];
      const script = document.createElement('script');
      script.src = '//cdn.by.wonderpush.com/sdk/1.1/wonderpush-loader.min.js';
      script.defer = true;
      script.onload = () => {
        window.WonderPush.push([
          'init',
          {
            webKey: '50c6bf0c463ad198cbf457f55fc83045a4a54de0b245039f9f8740ab675421f5',
          },
        ]);
      };
      document.body.appendChild(script);
    }
  }, [analyticsContext?.type]);

  return null;
};

export default WonderPush;
